.car-display {
  height: 450px;
  width: 353px;

 
}

.car-display .overlap-group-3 {
  height: 479px;
  position: center;
  width: 353px;
    transform: scale(0.65);

    

}

.car-display .slick-slide {
  display: inline-block;
}

.car-display .slick-slide img {
  width: 100%;
  height: 235px;


}






.car-display .CAR {
  height: 234px;


  width: 350px;
  object-fit:cover !important; /* This ensures that the image covers the full area */
  cursor:pointer;
  border-top-left-radius: 1rem;
border-top-right-radius: 1rem;

}


.car-display .rectangle {
  /* background-color: #ffffff; */
  background-color: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);

  border: 1px solid;
  height: 235px;
  position: absolute;
  cursor: pointer;
border-bottom-left-radius: 2rem;
border-bottom-right-radius: 2rem;

  top: 244px;
  width: 353px;
}
.car-display .image-container {
  position: relative;
}

.car-display .favorite-button {
  position: absolute;
  top: 20px;
  right: 5px;
  background: none;
  transform: scale(1.3);
border:none;
opacity:100%;
color: gray; /* Default color */
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s ease;
}
.car-display .favorite-button.favorited {
  color: red; /* Color when favorited */
}

.car-display .favorite-button:hover {
  color: darkred; /* You can change the hover color as well */
}

.car-display .rectangle-2 {
  background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
  border-radius: 0.5rem;
  height: 45px;
  left: 91px;
  position: absolute;
  top: 218px;
  width: 178px;
  align-items: center;
  cursor: pointer;

  text-align: center;
}

.car-display .Name{
  color: #3d8b78;
  font-family: "Copperplate";
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 280px;
  white-space: nowrap;
  cursor: pointer;

}

.car-display .element-reviews {
  color: #e4d52c;
  font-family: "Copperplate";
  font-size: 18px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 340px;
  white-space: nowrap;
}

.car-display .span {
  font-weight: 500;
}

.car-display .text-wrapper-4 {
  font-family: "Copperplate";

  color: #000000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.car-display .car-type {
  height: 16px !important;
  left: 201px !important;
  position: absolute !important;
  top: 360px !important;
  width: 20px !important;
}

.car-display .driver-instance {
  height: 20px !important;
  left: 201px !important;
  position: absolute !important;
  top: 431px !important;
  width: 20px !important;
}
.car-display .infobox {
  font-family: "Copperplate";
margin-top:8px;
  background-color: #ffffff;
  height: 240px;
  width: 354px;
border-color: #000000;
  border-bottom-left-radius: 2rem;
border-bottom-right-radius: 2rem;
display: flex; /* Enables flexbox */
  flex-direction: column; /* Stacks flex items (children) vertically */
  align-items: center; /* Centers flex items horizontally in the container */
  text-align: center; /* Centers text inside the flex items */

}
.car-display .info {
  height: 224px;
  position: absolute;
  top: 352px;
  
  width: 265px;
}



.car-display .element {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 22px;
  white-space: nowrap;
  align-self: flex-start; /* Aligns this item to the start of the flex container (left-aligned) */
  text-align: left; /* Left aligns the text inside the 'location' class */
  width: 100%;
}

.car-display .SUV {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  height: 24px;
  left: 181px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 22px;
  white-space: nowrap;
  align-self: flex-start; /* Aligns this item to the start of the flex container (left-aligned) */
  text-align: left; /* Left aligns the text inside the 'location' class */
  width: 100%;
}

.car-display .automatic {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.car-display .red {
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  height: 24px;
  left: 181px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.car-display .loc {
  color: #000000;
  font-family: "Copperplate";
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 310px;
  white-space: nowrap;
  cursor: pointer;
}

.car-display .self-driven {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 300;
  height: 24px;
  left: 181px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 79px;
  white-space: nowrap;
}

.car-display .petrolicon {
  height: 39px;
  left: 0;
  position: absolute;
  top: 75px;
  width: 31px;
}

.car-display .settingsicon {
  height: 18px;
  left: 3px;
  position: absolute;
  top: 43px;
  width: 25px;
}

.car-display .vector-2 {
  height: 24px !important;
  left: 197px !important;
  position: absolute !important;
  top: 394px !important;
  width: 24px !important;
}

.car-display .reviewstars {
  height: 13px;
  position: absolute;
  top: 307px;
  width: 87px;
}
.clickable-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.rectangle-2 {
  width: 100px;
  height: 50px;
  background-color: blue;
  transition: background-color 0.5s ease;
}


.car-display .rs {
  color: #ffffff;
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  left: 115px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 228px;
  cursor: pointer;

  white-space: nowrap;


}

