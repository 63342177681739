.car-mini {
  height: 450px;
  width: 353px;

  /* Change the value as per your requirement, 0.9 will reduce the size to 90% */
}

.car-mini .overlap-group-3 {
  height: 479px;
  position: center;
  width: 359px;
transform: scale(0.9);
}


.car-min .text-wrapper-4 {
  color: #000000;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.car-mini .CAR {
  height: 253px;

  width: 362px;
  object-fit: cover !important; /* This ensures that the image covers the full area */
  cursor: pointer;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.car-mini .rectangle {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #a0a0a0;
  height: 100px;
  position: absolute;
  cursor: pointer;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  top: 244px;
  width: 357px;
}
.car-mini .image-container {
  position: relative;
  display: inline-block;
}

.car-mini .favorite-button {
  position: absolute;
  top: 20px;
  right: 5px;
  background: none;
  transform: scale(1.3);
  border: none;
  opacity: 100%;
  color: gray; /* Default color */
  font-size: 22px;
  cursor: pointer;
  transition: color 0.3s ease;
}
.car-mini .favorite-button.favorited {
  color: red; /* Color when favorited */
}

.car-mini .favorite-button:hover {
  color: darkred; /* You can change the hover color as well */
}

.car-mini .rectangle-2 {
  background: linear-gradient(
    180deg,
    rgb(28, 47, 149) 0%,
    rgb(58.55, 64.39, 71.7) 100%
  );
  height: 30px;
  position: absolute;
  top: 240px;
  width: 360px;
  align-items: center;
  cursor: pointer;

  text-align: center;
}

.car-mini .Name {
  color: #000000;
  font-family: 'Poppins', Helvetica;
  font-size: 18px;
  position: absolute;
  text-align: left;
  top: 275px;
  left:10px;
  white-space: nowrap;
  cursor: pointer;
}

.car-mini .element-reviews {
  color: #000000;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  left: 144px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 321px;
  white-space: nowrap;
}

.car-mini .span {
  font-weight: 500;
}





.car-mini .element {
  color: #000000;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 300;
  height: 24px;
  left: 41px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}



.car-mini .lahore-2 {
  color: #000000;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 300;
  height: 24px;
  left: 41px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 79px;
  white-space: nowrap;
  width: 60px;
}

.car-mini .span {
  font-weight: 500;
}


.car-mini .reviewstars {
  height: 12px;
  left: 250px;
  position: absolute;
  top: 307px;
  width: 80px;
}
.clickable-container {
  cursor: pointer;
  display: flex;
}


.car-mini .rs {
  color: #ffffff;
  font-family: 'Poppins', Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 18px;
  left: 115px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 244px;
  cursor: pointer;

  white-space: nowrap;
}
