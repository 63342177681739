.slick-slider .slick-dots li.slick-active button:before {
    color: #21408E;
    font-size: 15px !important;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 15px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

 
.slick-slide > div {
  margin: 0 5px;
}
.slick-list {
  margin: 0 -5px;
}
.book_button{
  background:#21408E;
  borderRadius:5px;
   text-transform: uppercase;
   text-align: center;
   background: linear-gradient(180deg, rgb(28, 47, 149) 0%, rgb(58.55, 64.39, 71.7) 100%);
   padding: .5rem;
    font-weight:600
    
}

.price-item{
  font-size:1rem;
  font-family:'Poppins'
}
