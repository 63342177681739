@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }

   
}

.NavItem{

  display: flex;
  justify-content: center;
  align-items: flex-center ;
}


::selection {
  background-color: #2c2c2c;
  color: #fff;
}

body {
  --sb-track-color: #949494;
  --sb-thumb-color: #1F4590;
  --sb-size: 9px;
}

body::-webkit-scrollbar {
  width: var(--sb-size);
}

body::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 3px;
}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}