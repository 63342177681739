@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    html {
      font-family: "calibri";
    }
  }

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;800;900&display=swap");

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
      font-family: "calibri";

} 

body {
  margin: 0;
  font-family: 'calibri','Trebuchet', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'calibri', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

