.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
}

.authform {
  width: 100%;
  max-width: 350px;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loginTitle {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  text-align:center;
  text-transform: uppercase;
}


.google {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  background-color: #db0909;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.google:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.center {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.or {
  margin: 0 10px;
  font-weight: 600;
text-align: center;}

.right {
  margin-bottom: 20px;
}

.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: 600;
}

input[type="email"],
input[type="password"] {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #000;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

